.spinnerContainer {
  top: 0;
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinnerCalendar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.back-button-calendar {
    position: sticky;
    align-self: start !important;
    color: white;
    border: none;

    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
