.bodySelectTourList {
padding: 1rem;
height: 100%;
width: 100%;
}

@keyframes expanseBentoTourList {
  0% {
    opacity: 0;
      transform: scale(0.25);
      transform: translateY(50%);
  }

  50% {
    opacity: 1;
      transform: scale(0.5);
      transform: translateY(0%);
  } 

  100% {
      transform: scale(1);
  }
}

.bodySelectTourList a{
  animation: expanseBentoTourList 2s;
}

.spinnerList {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.skeletonTourListPreload{
  background-color: red;
  height: 100%;
  height: 100svh;
  min-height: 100%;
}