  .confirmationTitle {
    font-size: 24px;
    font-size: 1.5em;
    font-size: 2rem;
    font-weight: bold;
  }

  .confirmationDetail {
    font-size: 1.2em;
    font-size: 1.2rem;
    color: #333;
    font-size: 18px;
  }


  .confirmationLabel {
    width: 100%;
    display: block;
  }

  .confirmationInput {
    width: 100%;
  }

  .confirmationBackButton {
    position: sticky;
    /* top: 0; */
    /* background-color: #007bff; */
    align-self: start !important;
    /* background-color: #ff5a5f; */
    color: white;
    border: none;

    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 20px; */
  }


  .confirmationLabel {
    font-size: 16px;
  }

  .confirmationInput {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .confirmationButton {
    /* margin-top: 2rem; */
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
  }


  /*  */

  .spinnerOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .vDivider {
    display: flex;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    flex-grow: 1;
  }

  .conditionalContainer {
    width: 100%;
    grid-template-columns: 2fr 0.964fr;
    grid-template-rows: auto;
    flex-grow: 1;
    align-items: start;
    display: flex;
    border-radius: 12px;
    width: 100%;
  }

  .leftConfirmationScreen {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .rightConfirmationScreen {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .confirmationScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    min-height: 100%;
    justify-content: center;
    position: relative;
  }

  /* Estilos generales */
  .leftConfirmationScreen,
  .rightConfirmationScreen {
    flex: 1;
  }

  .confirmationInput,
  .confirmationInputCheckbox {
    width: 100%;
    /* padding: 10px; */
    border: 1px solid #ccc;
    border-radius: 6px;
    /* margin-top: 5px; */
    font-size: 1rem;
  }

  .confirmationInputCheckbox {
    width: auto;
    display: inline-block;
  }

  .confirmationLabel {
    font-size: 1rem;
    /* margin-bottom: 5px; */
    display: block;
    font-weight: bold;
    /* background-color: #28a745;
    height: 100px; */
  }


  .spinnerContainer {
    position: absolute;
    text-align: center;
    align-content: center;
    height: auto;
    z-index: 999;
  }

  /* Divider styles */
  .vDivider {
    height: 100%;
    border-left: 2px solid #e2e8f0;
  }

  .rounded {
    border-radius: 8px;
  }

  .confirmFade {
    display: flex;
    height: 100%;
    transform: none !important;
    flex-grow: 1;
  }

  .listbox{
    position: relative;
    background: red !important;
    /* background-color: red !important; */
  }

  /*///////////*/
  /* Phone Ver */
  /*///////////*/