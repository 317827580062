.tourSelection {
    /* background-color: aquamarine; */
    width: 100%;
    height: 100%;
    /* display: grid;
    grid-template-rows:
    10%
    auto; */
}

.fade-out {
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
