.humanData {
    /* justify-content: center; */
    display: flex;
    /* width: 100%; */
    flex-direction: column;
}

.adultWrapperHumanData {
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: end;
}

.adultTextFieldHumanData {
    /* background-color: red; */
    width: 100%;
    height: 100%;
}

/* 
.adultIncreaseButtonHumanData {
    background-color: pink !important;

}

.adultDecreaseButtonHumanData {
    background-color: peru !important;
} */