.motionContainerStyle{
  display: flex;
  /* background-color: blanchedalmond; */
  flex-grow: 1;
  /* width: 100%; */
  /* height: 100%; */
}

/* .carousel .slide {
  background: none;
}

.carousel .control-arrow,
.carousel .carousel-status {
  display: none;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
}

.arrow.prev {
  left: 10px;
}

.arrow.next {
  right: 10px;
} */
#root {
  white-space: pre-line;
}
