.cancelScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100svh;
    text-align: center;
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
  }
  
  .cancelScreen h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .cancelScreen p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .cancelScreen a {
    color: #0056b3;
    text-decoration: underline;
  }
  