.minorsData {
    /* background-color: #e9de15 !important; */
    /* margin-top: 8px; */
    justify-content: start;
    display: flex;
    width: 100%;
    /* height: 100%; */
    flex-direction: column;
  }

  