.dismiss {
    width: fit-content;
    height: fit-content;
    border: 2px solid rgb(238, 239, 242);
    border-radius: 4px;
    background: rgb(238, 239, 242);
    grid-area: 1 / 3;
    z-index: 2;
    position: sticky;
    /* inset-block-start: clamp(8px, 6px + 0.5vw, 16px);
    margin-block-start: calc(clamp(16px, 12px + 1.25vw, 36px) * -1); */
    margin-inline-end: clamp(8px, 6px + 0.5vw, 16px);
    justify-self: flex-end;
}