.textWrapperGeneral {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* padding: */
    margin-top: 4px;
}

.textWrapperGeneral_divider {
    width: 98.5%;
    position: absolute;
    bottom: 0;
    right: 0.75%;
}