.reservationManager {
    display: flex;
    position: relative;
    /* background-color: #cefc16; */
    background-color: white;
    width: 100%;
    height: 100%;
    /* height: auto; */
    /* background: linear-gradient(to top,
    rgba(31, 31, 31, 0.9) 0%,
    rgba(31, 31, 31, 0.9) 20%,
    rgba(31, 31, 31, 0.852589) 26.67%,
    rgba(32, 32, 32, 0.768225) 33.33%,
    rgba(33, 33, 33, 0.668116) 40%,
    rgba(34, 34, 34, 0.557309) 46.67%,
    rgba(35, 35, 35, 0.442691) 53.33%,
    rgba(36, 36, 36, 0.331884) 60%,
    rgba(37, 37, 37, 0.231775) 66.67%,
    rgba(38, 38, 38, 0.147411) 73.33%,
    rgba(39, 39, 39, 0.0816599) 80%,
    rgba(39, 39, 39, 0.03551) 86.67%,
    rgba(39, 39, 39, 0.0086472) 93.33%,
    rgba(39, 39, 39, 0) 100%) !important; */
    /* background-image: repeating-linear-gradient(
        38.25deg,
        rgba(0, 0, 0, 0.1) 0px,
        rgba(0, 0, 0, 0.1) 3.3px,
        transparent 0px,
        transparent 7px
      );
      background-size: 1920px 1080px;
      background-repeat: no-repeat;      */


}

.reservationManager-header {
    display: flex;
    width: 100%;
    height: 100%;
    /* background-color: #00402c; */
}

.preScreen{
    display: flex;
    /* background-color: aquamarine; */
    width: 100%;
    height: 100%;
}
