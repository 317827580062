/* StickyDrawer.css */

/* Contenedor principal del portal */
.liberty {
    position: fixed;
    z-index: 10000;
    /* Asegura que esté por encima de otros elementos */
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
}

/* Estilos del drawer */
.stickyDrawerConfirmationScreen {
    width: 100%;
    max-width: 500px;
    /* Ancho máximo del drawer */
    height: 80vh;
    /* Altura total del drawer */
    background-color: #fff;
    /* Fondo blanco para el recuadro */

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(90vh);
    /**/
    transition: transform 0.3s ease-in-out;
    pointer-events: auto;
    /* Habilita interacciones */
    position: relative;
}

/* Estado abierto del drawer */
.liberty.open .stickyDrawerConfirmationScreen {
    transform: translateY(0);
    /* Totalmente visible */
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    pointer-events: all;
    /* Fondo semi-transparente */
    z-index: -21;
    /* Asegura que esté debajo del contenido del drawer */
}

/* Estilos de la sección de manejo (handle) */
.handle {
    display: flex;
    flex-direction: row;
    align-items: end;

    justify-content: space-between;
    cursor: pointer;
    /* Indica que es interactivo */
    background-color: #c21b1b;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: absolute;
    top: 0;
    /* Siempre visible en la parte inferior del viewport */
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

/* Transiciones para fadeIn y fadeOut */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

/* Contenido del drawer cuando está abierto */
.drawer-content {
    display: flex;
    pointer-events: all;
    width: 100%;
    border-radius: 8px;
    overflow-y: auto;
    height: 100%;
    max-height: 80vh;
    /* max-height: 8vw; */
    /* background-color: aquamarine; */
}

/*///////*/
.totalTextContainerStickyDrawer {
    display: flex;
}

/* StickyDrawer.css */

/* Clases para la animación del contenido del drawer */
.drawer-content-enter {
    opacity: 0;
}

.drawer-content-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.drawer-content-exit {
    opacity: 1;
}

.drawer-content-exit-active {
    opacity: 0;
    transition: opacity 100ms;
    /* Transición rápida para el fade out */
}

/* Si ya tienes clases para la animación de la handle, puedes mantenerlas */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

/* Clases para la animación del handle */
.fade-handle-enter {
    opacity: 0;
}

.fade-handle-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-handle-exit {
    opacity: 1;
}

.fade-handle-exit-active {
    opacity: 0;
    transition: opacity 100ms;
}

.slideUpIconContainerStickyDrawer {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.slideDownIconContainerStickyDrawer {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.spinnerOverlayOnStickyDrawer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    pointer-events: all;
    scroll-behavior: none;
}

.spinnerContainerOnStickyDrawer {
    pointer-events: all;
}