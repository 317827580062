.price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    color: white;
    background-color: green;
    padding: 4px;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    line-height: 0;
    font: normal normal 400 1rem/calc(6px + 2.125ex) 'Porsche Next';
}

.price span .priceInfo1 {
    text-align: center;
    margin: 0;
    /* line-height: 1; */
}
.price span .priceInfo2 {
    text-align: center;
    margin: 0;
    /* line-height: 1; */
}

.vitalInfo {
    align-items: center;
    /* Esto asegura que ambos elementos estén alineados verticalmente */
    /* margin-top: 10px; */
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-content: center;
    /* margin-top: 10px; */
    /* padding-bottom: 10px; */
    /* height: 100%; */
    /* min-height: 8svh; */
    /* background-color: yellowgreen; */
}

.informationPersonsContainer {
    display: flex;
    height: 100%;
    /* min-height: 8vh; */
    max-height: 100%;
    width: auto;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    font-size: 1.3rem;
    padding: 0.5% 1.25% 0.5% 1.25%;
    justify-content: center;
    align-items: center !important; 
    align-content: center;
    box-sizing: border-box;
    align-items: stretch;
    /* flex-grow: 1; */
}

.informationPersonsContainer p {
    text-shadow: 0;
    padding-top: 3px;
    align-items: center;
    align-content: center;
    text-align: center;
    justify-content: center;
}

.tuktuk-1 {
    fill: #010205;
}

/* Icons Height */
.tuktukSVG {
    /* max-width: 11.5%; */
    /* max-height: 26px; */
    min-height: 25px;
    height: 50%;
    /* width: 10%; */
    aspect-ratio: 1/1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.personSVG {
    min-height: 25px;
    height: 50%;
    /* width: 10%; */
    aspect-ratio: 1/1;
    stroke-width: 1.4px;
}

.vitalInfoBase {
    align-items: center;
    /* margin-top: 10px; */
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
    align-content: center;
    /* margin-top: 10px; */
    /* padding-bottom: 10px; */
    padding-left: 10px;
    padding-right: 10px;
    /* background-color: yellowgreen; */
}

.test {
    display: flex;
    background-color: yellow;
    /* width: 100px; */
    width: 100%;
    height: 100%;
    /* flex-grow: 1; */
    /* height: 100px; */
}

/* className="flex flex-row items-center testing" */
.vehicleInfo{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    /* background-color: rgb(212, 127, 16) !important; */
    /* width: fit-content !important; */
    /* max-width: 100%; */
}