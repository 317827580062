.fadeInCalendar{
  /* background-color: red; */
  width: 100%;
  height: 100%;
  padding-top: clamp(8px, 0.5vw + 6px, 16px);
  padding-bottom: clamp(8px, 0.5vw + 6px, 16px);
}

.time-selection {
  /* background-color: red; */
  /* position: absolute; */
  width: 100%;
  /* height: 100svh; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center;
  justify-content: start; */
  /* background-color: #ff0000;
  background-color: #f0f0f0; */
  z-index: 1000;
  /* padding: 20px; */
  padding:
    20px 0px 20px 0px;
  box-sizing: border-box;
  /* background-color: aquamarine !important; */
  /* box-shadow: inset 0px 0px 45px 0px rgba(0, 0, 0, 1); */
  /* -webkit-box-shadow: inset 0px 73px 161px -33px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 73px 161px -33px rgba(0,0,0,1);
  box-shadow: inset 0px 73px 80px -103px hsla(225, 55%, 45%, 0.56); */
}

/* Phone? */
.time-selection-sticky {
  display: flex;
  flex-direction: column;
  /* background-color: #007bff; */
  /* align-content: center; */
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  /* min-height: 100svh; */
  position: sticky;
  top: 16svh;
  box-sizing: content-box;
}

.back-button {
  position: absolute;
  top: 0;
  /* top: 7.5svh; */
  /* background-color: #007bff; */
  align-self: start !important;
  /* background-color: #ff5a5f; */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  /* margin-left: 20px; */
  margin-bottom: 20px;
  z-index: 999;
}

.back-button:hover {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.pSegmentedControlItemStyle {
  background: white;
  border-radius: 4px;
}

.pSegmentedControlStyle {
  justify-content: center;
}

.segmented-control-container {
  display: grid;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  /* background: white; */
  /* background: ; */
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 20px;
  box-sizing: border-box;
}

.PSegmentedControlItem {
  flex: 1;
  white-space: nowrap;
  /* Ensure the time is in a single line */
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-time-button {
  margin-top: 20px;
  padding: 10px 20px;
  /* background-color: #007bff; */
  /* color: white; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.select-time-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.centering-semgment-divisor {
  width: 100%;
  justify-content: center;
}


.spinnerTimeSel {
  /* background-color: blue; */
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rebeccapurple; */
  justify-content: center;
  align-items: center;
}

.timeSelHeadText {
  font: 400 1.5rem / calc(2.125ex + 6px) "Porsche Next", "Arial Narrow", Arial, "Heiti SC", SimHei, sans-serif !important;
  text-align: center;
  transform: translateY(-6px) translateZ(0px);
}