/* src/MainApp/pages/NotFound/NotFound.module.css */
.notFoundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px;
}

.title {
    font-size: 2.5rem;
    color: #343a40;
}

.description {
    font-size: 1.25rem;
    color: #6c757d;
    margin: 20px 0;
}

.backButton {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.backButton:hover {
    background-color: #0056b3;
}
