.tour-reservation {
    /* background-color: red; */
    text-align: center;
    /* padding: 14px 0px 20px 0px; */
    /* start | end | left | right | center | justify | match-parent */
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    /* width: 90%; */
    max-width: 400px;
    /* margin: 0 auto; */
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Alinea el contenido en la parte superior */

}

.tour-reservation h2 {
    /* padding-top: 0.5px;
    padding-left: 10%;
    padding-right: 10%; */
    font: normal normal 400 1rem/calc(6px + 2.125ex) 'Porsche Next';
}

/* .countdown-timer {
    font: normal normal 400 1rem/calc(6px + 2.125ex) 'Porsche Next';
} */

.reserve-button:hover {
    background-color: #0056b3;
}

/* .countdown-timer {
    font-size: 2em;
} */

.reserve-button {
    background-color: #007BFF;
    color: white;
    /* padding: 10px 20px; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
}

.reserve-button:hover {
    background-color: #0056b3;
}

.borderBeamAtBookside {
    justify-self: center;
    align-self: center;
    width: 104%;
    height: 104%;
}

.vitalInfoComponentOnCallToAction {
    display: flex;
    position: sticky;
    border-radius: 10px;
    max-width: 400px;
    /* margin: 0 auto; */
    align-items: center;
    justify-content: space-between;
    background-color: green;
    /* padding: 4px; */
    border-radius: 4px;
}