.informationBookSide {
    height: 100%;
    /* background-color: aqua; */
    grid-column: 2/3;
    grid-row: 1/3;
    width: 100%;
}



.infoSideBookButton {
    background-color: blue;
    color: white;
}
