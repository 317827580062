:root {
    --background: #ffffff;
}

.tourInformation {
    position: relative;
    width: 100%;
    height: 100%;
    grid-template-columns: 2fr 0.964fr;
    grid-template-rows: auto; 
    
    align-items: start;

    border-radius: 0.5rem;
}
.tourInformation > * {
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
    /* overflow: hidden;  */
  }
