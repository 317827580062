.slider-container {
    width: 100%;
    height: 100%;
    /* margin: 0 auto; */
    position: relative;
}


.card-title {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.card-info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    font-size: 14px;
}

.slider-button {
    position: absolute;
    top: 45%;
    /* 
    90%
     */
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
}

.slider-button.prev {
    left: 10px;
}

.slider-button.next {
    right: 10px;
}

.slider-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/*  */
.slick-slider {
    /* background-color: red; */
    width: 100%;
    max-height: 100%;
    height: 100%;
    display: grid !important;
    grid-template-rows: 90% 10%;
}

.slick-track {
    height: 100%;
    width: 100%;
    /* max-width: 100%; */
    /* height: 10%; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.slick-list {

    /* background-color: blue; */
    height: 100%;

}

.slick-dots {
    /* display: flex !important; */
    position: relative !important;
    background-color: yellow;
    height: min-content;
    bottom: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    align-items: center !important;
    justify-content: center;
    align-content: center !important;
    display: flex !important;
    /* bottom: -12px; */
    /* margin-bottom: 3%; */
}

.slick-slide {
    /* margin-top: -8px; */
    display: flex !important;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    /* transition: all .3s ease-in-out; */
}

/*  */
.slick-initialized {
    /* display: grid;N */
    /* grid-template-columns: 100%; */
    /* grid-template-rows: 95% 5%; */
    /* background-color: rgba(0, 0, 0, 0.8); */
    /* margin: 0 !important;
    padding: 0 !important;
    box-sizing: content-box !important;
    height: 100%;
    max-height: 100%; */
}


.slick-slider {
    /* background-color: yellow; */
    /* margin-bottom: 10px; */

}

/*  */


.skCardBg {
    height: 98%;
    width: 80%;
    background-color: #D8D8DB;
    /* background-color: red; */

}

/* Abrir */
/* .skCardBg[open] {
    transform: scale(0.9);
    scale: 1;
    background-color: #ca0000;
    transition: opacity 0.3s ease-in-out,
        opacity 0.3s ease,
        translate 0.3s ease;
    translate: 0px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    Estado de iniciado
    @starting-style {
        
        scale: 0;
        opacity: 0;
        translate: 0px 100px;
    }
} */

/* Cierre */
/* .skCardBg {
    transform: scale(1);
    transition: scale .3s ease,
        display .3s ease allow-discrete;
    scale: 0;
} */

/* .skCardBg.show {
    opacity: 1;
    transform: scale(1);
}

.skCardBg.closing {
    opacity: 0;
    transform: scale(0.9);
    pointer-events: none;
} */


.pspinnerSmoothSlider {
    width: 100%;
    align-self: center;
    justify-content: center;
    /* background-color: aquamarine; */
    /* position: absolute; */
}

.sliderStyle {
    background-color: aliceblue;
}


.newCard {
    /* padding: 10px; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* align-items: start; */
    /* width: auto; */
    /* background-color: red; */
    /* 
    width: 100%; display: inline-block; 
    */
}

.newCardImage {
    /* background-color: blue; */
    /* padding: 10px; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-items: start;
    /* 
    width: 100%; display: inline-block; 
    */
}