.borderedContainerComponent {
  --background-color: transparent;
  height: 100%;   
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  border-radius: 0.5rem;
  border-width: 1px;
  background-color: var(--background-color);
  /* padding: 1rem; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* overflow: hidden; */
}