.informationDescriptionSide {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.infoSliderContainer {
    display: flex;
    position: relative;
    max-height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
    width: 92.25%;
}

.smoothContainerInfoSide {
    /* display: flex; */
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    justify-content: center;
}

.infoArea {
    background-color: white;
    grid-row: 3/4;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.listContact {
    display: flex;
    flex-direction: row;
}

/* Icons Height End*/

.vDivider {
    display: flex;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    flex-grow: 1;
}

.hDivider {
    padding-top: 13px;
}

.spinnerContainerInfo {
    display: flex;
    min-height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    align-content: center;
    justify-content: center;
    position: relative;
}

.pspinnerInfo {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.hDividerText {
    height: 20px;
    color: #000;
    align-content: center;
}

.accordionsInfo {
    height: 100%;
}
