/* src/App.css */
.App {
    text-align: center;
    padding: 20px;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
