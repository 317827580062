.footerSelect {
    display: flex;
    flex-direction: column;
    /* background-color: rgb(44, 167, 33); */
    justify-content: end;
    align-items: end;
}

.footerSelectBody {
    /* background-color: aliceblue; */
}

.footerSelectBodyHead {
    display: flex;
    justify-content: end;
    /* align-items: ""; */
    width: max-content;
}

.footerPoweredBy {
    background: linear-gradient(270deg, rgba(31, 31, 31, .9) 0, rgba(31, 31, 31, .9) 20%, rgba(31, 31, 31, .852589) 26.67%, rgba(32, 32, 32, .768225) 33.33%, rgba(33, 33, 33, .668116) 40%, rgba(34, 34, 34, .557309) 46.67%, rgba(35, 35, 35, .442691) 53.33%, rgba(36, 36, 36, .331884) 60%, rgba(37, 37, 37, .231775) 66.67%, rgba(38, 38, 38, .147411) 73.33%, rgba(39, 39, 39, .0816599) 80%, rgba(39, 39, 39, .03551) 86.67%, rgba(39, 39, 39, .0086472) 93.33%, rgba(39, 39, 39, 0));
    /* background: linear-gradient(90deg,rgba(31,31,31,.9) 0,rgba(31,31,31,.9) 20%,rgba(31,31,31,.852589) 26.67%,rgba(32,32,32,.768225) 33.33%,rgba(33,33,33,.668116) 40%,rgba(34,34,34,.557309) 46.67%,rgba(35,35,35,.442691) 53.33%,rgba(36,36,36,.331884) 60%,rgba(37,37,37,.231775) 66.67%,rgba(38,38,38,.147411) 73.33%,rgba(39,39,39,.0816599) 80%,rgba(39,39,39,.03551) 86.67%,rgba(39,39,39,.0086472) 93.33%,rgba(39,39,39,0)); */
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}