.confirmButton {
    /* background-color: antiquewhite; */
    width: 100%;
}

.testCol{
    position: sticky;
    top: 2.5svh;
    display: flex;
    flex-direction: column;
    width: 100%;
}