/* AppLandScapeEnforcer.css */

.flexSpace {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; /* Ajusta según tus necesidades */
  }
  
  .mainWrapper {
    background-color: #af4949c4; /* Fondo blanco o según diseño */
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    /* Otros estilos según sea necesario */
  }
  