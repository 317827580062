/* styles.css */

html {
    /* overflow-y: scroll; */
    touch-action: manipulation;
    /* Fallback */
    /* overscroll-behavior: none; */
    /* Previene el efecto de estiramiento */
    height: 100svh;
}

/* #Pre - PC */


/* #1 */
.tourContainerGrid {
    /* background-color: chartreuse; */
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    /* overflow: hidden; */
}

/* #2 */
/* HeadSelect */

/* #3 */
.phoneHeader {
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* background-color: rgb(60, 207, 111); */
}

/* #4 */
.headMainWrap {
    /* DISABLE BG */
    /* background-color: white; */
    z-index: 1000;
    /* conditional position */
    top: 0;
    display: flex !important;
    /* background-color: rgb(66, 51, 179); */
    /* assingned 14vh on PC */
    width: 100%;
}

/* #5 */
.absoluteWrapper {
    /* assingned 12vh and max, on PC */
    width: 100%;
}

/* #6 */
.mainHeadSelectWrapper {
    position: sticky !important;
    background-color: whitesmoke;
    /* background-color: rgb(146, 0, 0); */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: end;
    overflow: hidden;
}

/* #7 */
.dividerOnContainer {
    /* background-color: white; */
    /* background-color: burlywood; */
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.54); */
    height: 2svh;
    width: 100%;
    flex-shrink: 0;
    align-content: center;
}

.bodySelect {
    /* background-color: wheat; */
    /* assigned 14vh top */
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    height: auto;
    min-height: 100svh;
}

.motionDivContainer {
    grid-row: 2/3;
    display: flex;
}

/* -------- */

/* styles.css */
@keyframes slide-out-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

/* Logos */

.logosContainer {
    z-index: 1;
    position: relative;
    height: 100%;
    padding: 1.25%;
    margin-right: 5%;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeInBgLogo {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0;
    }

    100% {
        opacity: 0.3;
    }
}

@keyframes fadeInBackButton {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0;
        transform: translateX(-50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.logo {
    position: relative;
    z-index: 2;
    border-radius: 100%;
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.54);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.54);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.54);
    animation: rightToLeftLogo 300ms linear;
    transition: transform .075s ease-in-out, filter .04s ease-in-out;
}

.logo:hover {
    transform: scale(1.055);
    /* pointer-events: ; */
    cursor: pointer;
}

.logo:active {
    filter: brightness(1.2);
    /* Aumenta el brillo para aclarar el botón */

    /* box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.7); */

}

/* .logosContainer:hover { transform: scale(1.1); } */

@keyframes rightToLeftLogo {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

/* Logos End*/

.languageSelectorPhone {
    width: fit-content;
    height: fit-content;
    height: 100%;
    max-height: 28px;
    border: 2px solid rgb(238, 239, 242);
    border-radius: 4px;
    background: rgb(238, 239, 242);
    z-index: 2;
    position: sticky;
    /* inset-block-start: clamp(8px, 6px + 0.5vw, 16px); */
    /* margin-block-start: calc(clamp(16px, 12px + 1.25vw, 36px) * -1); */
    margin-inline-start: clamp(4px, 3px + 0.25vw, 8px);
}

.bgLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    /* Ajusta el tamaño del logo más pequeño */
    height: auto;
    /* Mantén la proporción del logo */
    z-index: -1;
    opacity: 0.3;
    animation: fadeInBgLogo 2s linear forwards;
    /* animation: fadeInBgLogo 3s linear delay iteration-count forwards fill-mode; */

}

.textTitleContainer {
    /* position: absolute; */
    z-index: -1;
    display: flex;
    /* left: 54px; */
    /* top: -4%; */
    width: 100%;
    /* width: auto; */
    /* height: 100%; */
    /* padding-left: -1 %; */
    /* background-color: #4299e1b7; */
    /* background-color: #4299e1; */
    /* align-items: center; */
    justify-content: left;
    text-align: left;
}

.textTitleContent {
    /* width: 73.5%; */
    width: 100%;
    height: 100%;
    align-content: center;
    box-sizing: content-box;
    /* background-color: red; */
    margin-bottom: 0;
    animation: fadeInBackButton .5s linear forwards;
}

.pButtonBackForwardHeadSelect {
    /* position: absolute; */
    justify-content: center;
    align-content: center;
    max-width: 54px;
    width: 100%;
    top: 25%;
    top: 0;
    left: 0;
    /* top: 1px; */
    margin: 1px;
    /* justify-self: start !important; */
    /* align-self: flex-start; */
}

/* calc(clamp(8px, 0.5vw + 6px, 16px) + clamp(16px, 1.25vw + 12px, 36px))    */

.backForwardButtonContainerOnHeadSelect {
    animation: fadeInBackButton .5s linear forwards;
    z-index: 2;
    /* background-color: red; */
    /* width: 100%; */
}
