.successScreen {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #d4edda;
    color: #155724;
    padding: 20px;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
  }

  .successScreen h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .successScreen p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .bookingDetails {
    text-align: left;
    margin-top: 1rem;
  }

  .bookingDetails p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  .backButton {
    margin-top: 2rem;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .backButton:hover {
    background-color: #218838;
  }


  /* src/MainApp/pages/StripePayment/SuccessScreen.module.css */
.successScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.successContainer {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.successIcon {
  color: #4BB543;
  font-size: 60px;
  margin-bottom: 20px;
}

.errorContainer {
  color: #D8000C;
}

.errorIcon {
  color: #D8000C;
  font-size: 60px;
  margin-bottom: 20px;
}

h1 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #555;
  margin-bottom: 20px;
}

.bookingDetails {
  margin-top: 30px;
  text-align: left;
}

.bookingDetails h2 {
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.detailsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.detailSection {
  flex: 1;
  min-width: 250px;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
}

.detailSection h3 {
  margin-bottom: 15px;
  color: #4BB543;
}

.detailSection p {
  margin: 5px 0;
  color: #555;
}

.backButton {
  margin-top: 30px;
  padding: 12px 30px;
  background-color: #4BB543;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.backButton:hover {
  background-color: #43a036;
}
